/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.main-bg{
    background-color: var(--ion-color-primary);
}
.ion-text-nowrap {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
}
ion-grid.main-bg {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
.custom-card {
    overflow: hidden;
    margin: 10px;
}
.custom-card img{
    border-radius: 20px;   
}
.fixed-title{
    position: fixed;
    z-index: -1;
    width: 100%;
}
.cuna{
    height: 60px;
}
.librito-container{
    background:var(--ion-color-primary) ;
    border-radius: 100%;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    text-align: center;
    padding-top: 13px;
    float: right;
}
.librito-header{
    width: 30px;
    height: auto;
}
iframe.edicion-frame {
    border: 0 none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: white;
}